
.custom-navbar {
    background-color: #F9F2D9 !important; 
    position: fixed !important;
    top: 0; 
    left: 0; 
    right: 0; 
    z-index: 1000; 
    background-color: #F9F2D9 !important; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}
.logo_mouveup{
   height:50px ;
   padding: 0px;
}
.navbar-brand {
    color: #7c4512 !important; 
}
.header-link{
 font-size: 18px !important;
}
.nav-link {
    color: #793402 !important; 
    position: relative; 
    font-family: 'poppins'; 
    font-size: 17px;
    font-weight: 500 !important; 
    margin-left: 30px; 
    transition: color 0.3s;
}

.nav-link:hover {
    color: #DB6612 !important; 
}

.nav-link::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px; 
    background: #DB6612; 
    position: absolute;
    left: 0;
    bottom: -2px;
    transform: scaleX(0); 
    transition: transform 0.4s ease; 
}

.nav-link:hover::after {
    transform: scaleX(1); 
}

.form-select {
    background-color: #F9F2D9 !important;
    color: #793402 !important; 
    border: 1px solid #793402 !important;
}

.nav {
    margin-left: 20px; 
}

