
.card-grid {
    text-align: center;
    padding: 20px;
  
  }
  

  .card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .card-image {
    width: 100%;
    border-radius: 4px;
    height: 200px;
    object-fit: cover;
  }
  
  .card-title {
    color: #005baa;
    font-size: 18px;
    margin: 16px 0 8px;
  }
  
  .card-description {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
  }
  
  .card-link {
    color: #d2691e; 
    text-decoration: none;
    font-weight: bold;
  }
  .card-lli{
    
    text-decoration: none;
  }
  .card-link:hover {
    text-decoration: underline;
  }
  
  .view-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #d2691e; 
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .view-more-btn:hover {
    background-color: #b25d1b; 
  }
  