.footer {
    background-color: #8C4F2A;
    color: white;
    padding: 20px;
    border-radius: 10px 10px 0 0;
  }
  .logo_mouveup_footer{
    width: 200px;
    height: 200px;
  }
  .footer-top {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #a5673f;
  }
  .Link{
    text-decoration: none;
    font-size: 17px;
  }
  .Link:hover{
    text-decoration: underline;
    color: aliceblue;

  }
  .footer-section {
    width: 30%;
  }
  
  .logo-section h3 {
    font-size: 24px;
    color: white;
  }
  
  .footer-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .footer-section p,
  .footer-section li {
    font-size: 14px;
    color: #f1f1f1;
    margin: 5px 0;
  }
  
  .footer-section li {
    list-style-type: none;
  }
  
  .footer-section strong {
    color: #FF7F50;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  .social-icons a {
    color: white;
    font-size: 18px;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    color: #FF7F50;
  }
  

  @media (max-width: 768px) {
    .footer-top {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-section {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .footer-bottom {
      flex-direction: column;
      text-align: center;
    }
  
    .social-icons {
      margin-top: 10px;
    }
  }
  .footer {
    background-color: #8C4F2A;
    color: white;
    padding: 20px;
    border-radius: 10px 10px 0 0;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #a5673f;
}

.footer-section li i {
    margin-left: 8px;  
    font-size: 16px;   
    color: #FF7F50;   
    transition: transform 0.3s ease, color 0.3s ease;
}

.footer-section li:hover i {
    transform: translateX(5px); 
    color: aliceblue; 
}

.Link {
    text-decoration: none;
    font-size: 17px;
    color: white;
}

.Link:hover {
    text-decoration: underline;
    color: aliceblue;
}

.footer-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.footer-section p,
.footer-section li {
    font-size: 14px;
    color: #f1f1f1;
    margin: 5px 0;
}

.footer-section li {
    list-style-type: none;
}

.footer-section strong {
    color: #FF7F50;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.footer-bottom p {
    margin: 0;
}

.social-icons a {
    color: white;
    font-size: 18px;
    margin: 0 10px;
    text-decoration: none;
}

.social-icons a:hover {
    color: #FF7F50;
}


@media (max-width: 768px) {
    .footer-top {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-section {
        width: 100%;
        margin-bottom: 20px;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    .social-icons {
        margin-top: 10px;
    }
}
.footer {
    background-color: #8C4F2A;
    color: white;
    padding: 20px;
    border-radius: 10px 10px 0 0;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #a5673f;
}

.logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}



.logo-description {
    font-size: 14px;
    color: #f1f1f1;
    margin-top: 10px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section li i {
    margin-left: 8px;
    font-size: 16px;
    color: #FF7F50;
    transition: transform 0.3s ease, color 0.3s ease;
}

.footer-section li:hover i {
    transform: translateX(5px);
    color: aliceblue;
}

.Link {
    text-decoration: none;
    font-size: 17px;
    color: white;
}

.Link:hover {
    text-decoration: underline;
    color: aliceblue;
}

.footer-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.footer-section p,
.footer-section li {
    font-size: 14px;
    color: #f1f1f1;
    margin: 5px 0;
}

.footer-section li {
    list-style-type: none;
}

.footer-section strong {
    color: #FF7F50;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.footer-bottom p {
    margin: 0;
}

.social-icons a {
    color: white;
    font-size: 18px;
    margin: 0 10px;
    text-decoration: none;
}

.social-icons a:hover {
    color: #FF7F50;
}


@media (max-width: 768px) {
    .footer-top {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-section {
        width: 100%;
        margin-bottom: 20px;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    .social-icons {
        margin-top: 10px;
    }
}
.logo_mouveup_footer {
  max-width: 200px;  
}

.logo-description {
  font-size: 14px;
  color: #f1f1f1;
  margin-top: 10px; 
}