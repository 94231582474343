
.references {
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.references_titre {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin: 20px;
}

.iconChap_references {
  width: 50px;
  height: 50px;
}

.H3_references {
  color: #793402;
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.references_slider_div {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  background-color: #ffffff;
}

.logo-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.logo {
  height: 50px;
  max-width: 100px;
}
.references_slider{
  margin: auto;
}
.references-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 0px;
}

.references-grid .logo {
  height: 40px;
}
.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #793402; 
  cursor: pointer;
  z-index: 2;
}

.prev-arrow {
  left: -20px; }

.next-arrow {
  right: -20px; 
}

.slider-arrow:hover {
  color: #a35c04; 
}
