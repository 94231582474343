
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}


.map-section {
  width: 100%;
  margin-bottom: 20px;
}

.form-info-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  background-color: #FAF4F0;
  padding: 20px;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 20px;
}
.label-contact{
 color: #8C4F2A !important;
}

.contact-form,
.contact-info {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  box-sizing: border-box;
}

.contact-form h2,
.contact-info h2 {
  font-size: 18px;
  color: #8C4F2A;
  margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #CCC;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form .submit-btn {
  padding: 10px 20px;
  background-color: #8C4F2A;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form .submit-btn:hover {
  background-color: #A5673F;
}


.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #4C4C4C;
}

.contact-info p {
  margin: 10px 0;
}

.info-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.info-item i {
  font-size: 20px;
  color: #8C4F2A;
  margin-right: 10px;
}


.social-icons a {
  color: white;
  font-size: 18px;
  margin: 0 10px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #FF7F50;
}


.contact-info h2 {
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
  color: #8C4F2A;
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.popup-content p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.close-popup-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-popup-btn:hover {
  background-color: #218838;
}

.social-icon a {
  color: #8C4F2A;
  font-size: 18px;
  margin: 0 10px;
  text-decoration: none;
}

.social-icon a:hover {
  color: #FF7F50;
}

@media (max-width: 768px) {
  .form-info-section {
    flex-direction: column;
    padding: 10px;
  }

  .contact-form,
  .contact-info {
    width: 100%;
    padding: 10px;
  }

  .contact-form h2,
  .contact-info h2 {
    font-size: 16px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
  }

  .contact-info h2 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .contact-info p {
    font-size: 14px;
  }

  .info-item i {
    font-size: 16px;
  }

  .info-item p {
    font-size: 14px;
  }
}
