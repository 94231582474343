
.projects-container {
    margin-top: 30px;
    text-align: justify;
    margin: 20px;
}
.projects-intro {
    font-size: 1.1rem;
    color: #555;
    margin: 10px 0 30px;
    text-align: justify;
}

.projects-title {
    font-size: 2rem;
    color: #7c4b1d;
    margin-bottom: 20px;
    text-align: justify;
}


.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.project-card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
}

.project-card:hover .project-overlay {
    opacity: 1;
}

.project-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.project-description {
    font-size: 1rem;
    margin-bottom: 15px;
}

.project-link {
    color: #fff;
    text-decoration: underline;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.project-link:hover {
    color: #ffd700; 
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: justify;
}

.popup-content p {
    margin-bottom: 20px;
}

.popup-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-content button:hover {
    background-color: #0056b3;
}
