.service-detail-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 6%;
}

.main-service {
    text-align: center;
    margin-bottom: 30px;
}

.main-service h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.main-service p {
    font-size: 1.2em;
    color: #555;
}

.sub-service-item h3 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 5px;
}
.contentServices{
    margin-top:10px ;
}

.sub-service-item p {
    font-size: 1em;
    color: #666;
}

.discover-button {
    display: block;
    width: fit-content;
    margin: 30px auto 0;
    padding: 12px 24px;
    font-size: 1em;
    color: #fff;
    background-color: #8f3b04;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.discover-button:hover {
    background-color: #8a4b21;
}

.SUBservice-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap; 
}
.sub-services-text{
    width:40%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
}

.sub-services {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

}

.service-image2{
    width: 100%;
    border-radius: 4px;
    height: 200px;
    object-fit: cover;
}
@media (max-width: 768px) {
    .SUBservice-content {
        flex-direction: column;
        
    }
    .sub-services-text{
        width: 100%;
    }
    .sub-services{
        flex-wrap: wrap;
        flex-direction: column;
    }
    .service-image {
        width: 100%;
        height: 200px;
    }
}
