
.why-choose-us {
    text-align: center;
    padding: 20px;
    margin-top:30px ;
  }
  
  .section-title {
    color: #793402;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .reasons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    transition: transform 0.3s ease;
  }
  .reason-card:hover {
    transform: translateY(-5px);
}
  .reason-card {
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.418);
    border-radius: 8px;
    padding: 4px;
    background-color: #fff;    
    transition: transform 0.3s ease;
  }
  
  .reason-image {
    width: 100%;
    border-radius: 8px;
    height: 200px;
    object-fit: cover;
  }
  
  .reason-title {
    color: #005baa;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .reason-description {
    font-size: 14px;
    color: #333;
  }
  