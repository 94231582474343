
.services-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    margin-top: 5.5%;
}

.service-item {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.service-content {
    flex: 1;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    text-align: left;
}

.service-content h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.service-content p {
    font-size: 1em;
    margin-bottom: 20px;
}

.service-link {
    background: linear-gradient(135deg, #E8A270, #D29B5B); 
    color: #fdfdfd;
    border:none;
    border-radius: 10px;
    margin-right: 10px;
    padding: 6px 20px;
    margin-left: 20px;
    font-weight: 500;
    width: 150px;
    font-family: 'Arial', sans-serif; 
    transition: background 0.3s, color 0.3s; 
}

.service-link:hover {
    background: linear-gradient(135deg, #D29B5B, #E8A270); 
    color: #9E4505; 
}

.service-image {
    flex: 1;
    height: 250px; 
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    width: 100%; 
    object-fit: cover; 
}

@media (max-width: 768px) {
    .service-image {
        width: 100%;
        height: 200px;
    }
}


.image-right .service-image {
    order: 2;
}


.image-left .service-image {
    order: -1;
}


@media (max-width: 768px) {
    .service-item {
        flex-direction: column;
    }
    .service-image {
        width: 100%;
        height: 200px;
    }
}
