
.project-detail-container {
    margin-top: 6%;
    padding: 20px;
    background-color: #f8f9fa; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-detail-image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
}

.project-detail-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.project-detail-description {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;
}

.project-detail-info p {
    font-size: 16px;
    margin: 5px 0;
}

.project-detail-info strong {
    color: #333;
}
