
.carousel-image {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.carousel-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    padding: 0 10px;
    text-align: center;
}

.full-screen-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    transition: filter 0.3s;
}

.banner-content {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    gap: 20px;
    padding: 20px;
    color: #4B2E2A;
    border-radius: 10px;
}

.logo-section1 {
    display: flex;
    align-items: center;
}

.logo {
    width: 100px;
}

.logo_mouveup_home {
    width: 300px;
    height: auto; 
}

.vertical-line {
    width: 2px;
    height: 130px;
    background-color: #f8f8f8;
    margin-left: 10px;
}

.text-section h1 {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: #ffffff;
    max-width: 500px;
    margin: 0 auto;
}

.text-section {
    opacity: 0;
    transform: translateX(-100%);
    animation: slideIn 1.5s forwards;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.button-group {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.dark-brown, .light-brown {
    display: inline-block;
    width: 150px;
    padding: 10px 20px;
    text-align: center;
    font-weight: 500;
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    transition: background 0.3s, color 0.3s;
    border: none;
}

.dark-brown {
    background: linear-gradient(135deg, #5B2A02, #793402);
    color: #FFF;
}

.dark-brown:hover {
    background: linear-gradient(135deg, #e98035, #5B2A02);
    color: #FFF;
}

.light-brown {
    background: linear-gradient(135deg, #E8A270, #D29B5B);
    color: #fdfdfd;
}

.light-brown:hover {
    background: linear-gradient(135deg, #D29B5B, #E8A270);
    color: #9E4505;
}

.titre_chap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.iconChap {
    width: 60px;
    height: 60px;
}

.title_service_home {
    color: #793402;
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.img_home_reference {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .carousel-image {
        height: auto; 
    }

    .full-screen-image {
        height: 50vh; 
        object-fit: cover;
    }

    .carousel-text {
        justify-content: center; 
        padding: 10px; 
    }

    .banner-content {
        flex-direction: column; 
        gap: 10px;
        text-align: center;
    }

    .logo_mouveup_home {
        width: 150px; 
        height: auto;
    }

    .vertical-line {
        display: none;
    }

    .button-group {
        flex-direction: column; 
        align-items: center;
        margin-left: 0;
        gap: 10px;
    }

    .text-section h1 {
        font-size: 1.2em; 
        line-height: 1.2;
        max-width: 100%; 
    }
}

@media (max-width: 480px) {
    .banner-content {
        padding: 10px;
    }

    .text-section h1 {
        font-size: 1em;
    }

    .carousel-text {
        margin-top: 10px;
    }

    .logo_mouveup_home {
        width: 120px;
    }
}
