/* YouTubeChannel.css */
.youtube-channel {
    text-align: center;
    padding: 20px;
    margin-top: 30px;
  }
  
  .youtube-title {
    font-size: 24px;
    color: #d62828;
    display: flex;
    align-items: center;
    justify-content: justify;
    gap: 8px;
    text-align: justify;
  }
  .iconYou{
    width: 50px;
    height: 45px;
  }
  .youtube-description {
    font-size: 1.1rem;
    color: #000000;
    margin: auto;
    text-align: justify;
    width: 80%;
    margin-bottom: 30px;
    
  }
  
  .youtube-video iframe {
    max-width: 100%;
    border-radius: 8px;
  }
  