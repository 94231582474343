
.img_home_about {
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 10px;
}

.section_about {
    margin: 3%;
}

.titre_qui {
    color: rgb(119, 56, 14);
    font-size: 2rem;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.img_qui {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.p_qui {
    font-size: 1.1rem;
    color: #000000;
    margin: auto;
    text-align: justify;
    width: 80%;
    margin-bottom: 30px;
}

/* Ajout de styles responsives */
@media (max-width: 768px) {
    .img_home_about {
        height: 200px;
    }
    .section_about {
        margin: 5%;
    }
    .titre_qui {
        font-size: 1.5rem;
        flex-direction: column;
        text-align: center;
    }
    .p_qui {
        width: 90%;
    }
    .div_cont {
        flex-direction: column;
        align-items: center;
    }
    .imag_about_qui {
        width: 60%;
        height: auto;
    }
}

.div_cont {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.imag_about_qui {
    width: 40%;
    height: auto;
    object-fit: cover;
    margin-left: 20px;
}

footer {
    margin-top: 50px;
}


.section_about {
    margin: 3%;
}

.titre_qui {
    color: rgb(119, 56, 14);
}

.section_travaux {
    margin-top: 30px;
}

.list_travaux {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.travail {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.travail:hover {
    transform: translateY(-5px);
}

.travail h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
}

.travail p {
    color: #555;
    font-size: 1rem;
    line-height: 1.4;
}
